// 一鍵往上
"use client";
import Image from "next/image";
import { usePathname } from "next/navigation";

const GoTop = () => {
  const pathname = usePathname();

  const hidePath = ["/", "/cn", "/academy", "/cn/academy"];

  if (hidePath.includes(pathname)) return <></>;

  return (
    <button
      className="fixed z-[2] right-[2vw] bottom-[2vw] ulg:hidden"
      onClick={() => {
        if (typeof window !== "undefined") window.scrollTo({ top: 0 });
      }}
    >
      <div className="img-box w-[30px] h-[30px] group [&>img]:trans after:content-none mx-auto">
        <Image
          className="z-[1] group-hover:opacity-0 dark:filter-white"
          width={30}
          height={30}
          src="/images/icon/icon_go_top.svg"
          alt="go_top_button"
        />
        <Image
          className="z-[0] opacity-0 group-hover:opacity-[1] dark:hidden"
          width={30}
          height={30}
          src="/images/icon/icon_go_top_black.svg"
          alt="go_top_button"
        />
        <Image
          className="z-[0] hidden opacity-0 group-hover:opacity-[1] dark:inline-block"
          width={30}
          height={30}
          src="/images/icon/icon_top_dark.svg"
          alt="go_top_button"
        />
      </div>
      <span className="text-bd1b text-black">TOP</span>
    </button>
  );
};

export default GoTop;
