// 漢堡選單按鈕
import { useSelector } from "react-redux";
import { popState, setMenu } from "@/slices/popSlice";

const Hamburger = ({ dispatch }) => {
  const { showMenu } = useSelector(popState);

  return (
    <div
      onClick={() => dispatch(setMenu(!showMenu))}
      className="cp hidden uxl:flex flex-col items-center gap-y-[6px] !ml-[10px] w-[30px] px-[3px] py-[7px]
     [&>span]:w-[23px] [&>span]:h-[2px] [&>span]:rounded-[40px] [&>span]:trans"
    >
      <span
        className={`origin-top-left bg-black7 ${
          showMenu ? "translate-x-[4px] rotate-[45deg]" : ""
        }`}
      />
      <span className={`bg-pale ${showMenu ? "opacity-[0]" : ""}`} />
      <span
        className={`origin-bottom-left bg-black7 ${
          showMenu ? "translate-x-[4px] rotate-[-45deg]" : ""
        }`}
      />
    </div>
  );
};

export default Hamburger;
