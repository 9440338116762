import apiClient from "./apiClient";

/** 小鈴鐺通知 */
class NotificationClient {
  // 取得 小鈴鐺通知
  getNotifications = () => apiClient("/notification/list?site=cc");

  // 訊息已讀
  putNotifications = (payload) => apiClient.put("/notification/list", payload);

  // 新增個人通知
  postPersonalMsg = (payload) =>
    apiClient.post("/notification/personal-msg", payload);
}

const notificationClient = new NotificationClient();

export default notificationClient;
