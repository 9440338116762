// 彈窗用 熱門新聞
"use client";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import newsClient from "@/common/apiClient/newsClient";
const ModalPopular = ({ locale }) => {
  const { t } = useTranslation();
  const [hotNews, setHotNews] = useState([]); // 今日熱門三篇

  // 取得熱門新聞
  const getHotNews = async () => {
    try {
      const result = await newsClient.getPopularNewsByCategoryCode(
        locale,
        "Hot",
        3
      );
      if (result) setHotNews(result);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getHotNews();
  }, []);

  return (
    <div>
      <p className="mb-[5px] text-bd1b text-black usm:mb-0">{t("HOT")}</p>

      {/* 新聞列表 */}
      {hotNews?.map((news, i) => (
        <Link
          key={news?.slug + i}
          href={`/news/${news?.slug}`}
          className={`flex group items-center gap-x-[22px] py-[18px] border-dashed border-black2 usm:gap-x-[15px]
          ${i < hotNews?.length - 1 ? "border-b-1" : ""}`}
        >
          <span className="flex-shrink-0 w-[10px] h-[10px] bg-[#d9d9d9] trans group-hover:bg-pale" />

          <p className="text-bd1m text-black9 trans group-hover:text-pale line-clamp-1 opacity-[.9]">
            {news?.title}
          </p>
        </Link>
      ))}
    </div>
  );
};

export default ModalPopular;
