// 手機版選單
import { useSelector } from "react-redux";
import { userState } from "@/slices/userSlice";
import Image from "next/image";

import PageMenu from "../layout/nav/PageMenu"; // 網站選單連結
import MenuAppWrapper from "../layout/menu/MenuAppWrapper"; // app 連結
import MenuBanner from "../layout/menu/MenuBanner"; // 廣告 banner
import MenuLoginButton from "../layout/menu/MenuLoginButton"; // 登入註冊按鈕
import Subscribe from "../index/Subscribe"; // 訂閱電子報
import LanguageAndApp from "../layout/nav/LanguageAndApp"; // 切換語言、模式
import FooterSocial from "../layout/footer/FooterSocial"; // 社群連結

const MobileMenuModal = ({
  t,
  dispatch,
  setMenu,
  showMenu,
  locale,
  setUserMenuModal,
}) => {
  const { user } = useSelector(userState);

  return (
    <div
      className={`fixed w-screen h-screen top-0 left-0 trans justify-end hidden uxl:flex
        ${showMenu ? "z-[9] ulg:z-[100]" : "z-[-1]"}`}
    >
      {/* 關閉用 */}
      <div
        className="absolute w-screen h-screen top-0 left-0 z-[0]"
        onClick={() => dispatch(setMenu(false))}
      />

      {/* 選單 */}
      <div
        className={`trans relative z-[1] h-full w-[280px] bg-[#ffffff] dark:bg-[#1E1E1E] border-l-1 border-black2 
            px-[22px] pt-[77px] py-[27px] backdrop-blur-[10px] bg-opacity-[.85] space-y-[24px] noScrollY ulg:pt-[27px] usm:pb-[55px]
            ${!showMenu ? "translate-x-[283px]" : "translate-x-0"}`}
      >
        {/* 廣告banner */}
        <MenuBanner />

        {/* 登入註冊按鈕 / user資訊 */}
        {!user?.uid ? (
          <MenuLoginButton t={t} dispatch={dispatch} />
        ) : (
          <div
            className="cp border-t-1 border-b-1 border-dashed border-black3 py-[20px] flex items-center gap-x-[14px]"
            onClick={() => {
              dispatch(setMenu(false));
              dispatch(setUserMenuModal(true));
            }}
          >
            <div className="img-box w-[42px] h-[42px] rounded-[50%] border-[2px] border-[#fff] after:content-none overflow-hidden dark:border-[#c7c7c7]">
              <Image
                width={42}
                height={42}
                src={user?.image || "/images/layout/default_head.png"}
                alt="thumbnail"
                sizes="40px"
              />
            </div>
            <div className="space-y-[6px]">
              <p className="text-bd1b text-black">{user?.nickname}</p>
              <p className="text-bd3n text-black9 opacity-[.6]">@{user?.uid}</p>
            </div>
          </div>
        )}

        {/* 選單 連結 */}
        <PageMenu t={t} type="mobile" />

        {/* 手機選單 app 下載區塊 */}
        <MenuAppWrapper t={t} />

        {/* 訂閱電子報 */}
        <Subscribe type="mobile" />

        {/* 切換語言、模式 */}
        <LanguageAndApp t={t} locale={locale} />

        <div className="pb-[20px]">
          <FooterSocial />
          <p className="text-bd3n text-black text-center mt-[24px]">
            Copyright © 2024 Crypto City.
          </p>
        </div>
      </div>
    </div>
  );
};
export default MobileMenuModal;
