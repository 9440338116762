import EventSpeaker from "../events/EventsSpeaker";

const SpeakerIntroModal = ({
  showSpeakerModal,
  dispatch,
  setSpeakerModal,
  t,
}) => {
  if (!showSpeakerModal) return;

  return (
    <div className={`modal ${showSpeakerModal ? "act" : ""}`}>
      {/* 關閉用 */}
      <div
        className="close-bg"
        onClick={() => dispatch(setSpeakerModal(false))}
      />

      <div className="inner max-w-[790px] px-[60px] py-[30px] usm:p-[30px] !noScrollY !overflow-y-scroll">
        <EventSpeaker item={showSpeakerModal} introModal={true} />
        <div className="brief mt-[36px] mb-[60px] text-bd2n text-black usm:mb-[40px] usm:mt-[25px]">
          <p>{showSpeakerModal?.brief}</p>
        </div>

        <button
          className="trans text-btn1 flex-shrink-0 text-white block w-full px-[22px] py-[12px] border-1 border-jblack
                    rounded-[40px] bg-jblack hover:text-black hover:bg-white"
          onClick={() => dispatch(setSpeakerModal(false))}
        >
          {t("CLOSE")}
        </button>
      </div>
    </div>
  );
};

export default SpeakerIntroModal;
