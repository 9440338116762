// 手機版置底選單
import { useSelector, useDispatch } from "react-redux";
import { setLoginModal, setUserMenuModal } from "@/slices/popSlice";
import { useParams } from "next/navigation";
import { userState } from "@/slices/userSlice";
import Image from "next/image";
import Link from "next/link";

const bottomMenu = [
  {
    title: "NEWS",
  },
  {
    title: "FLASH",
  },
  {
    title: "PROJECT",
  },
  {
    title: "EVENTS",
  },
  {
    title: "USER",
  },
];

const MobileBottomMenu = ({ t }) => {
  const { newsSlug, academySlug } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector(userState);

  //   點擊我的
  const onClickUser = () => {
    if (!user?.uid) dispatch(setLoginModal(true));
    else dispatch(setUserMenuModal(true));
  };

  if (newsSlug || academySlug) return <></>;

  return (
    <div
      className="hidden h-[65px] ulg:block fixed bottom-0 left-0 w-screen z-[7] py-[7px] backdrop-blur-[10px]
    bg-[rgba(255,255,255,.85)] dark:bg-[rgba(14,14,14,.56)] px-[15px] border-t-1 border-[#e4e4e4]"
    >
      <div className="between-center max-w-[342px] w-full mx-auto [&>*]:flex [&>*]:flex-col [&>*]:items-center [&>*]:text-bd3n [&>*]:text-[#5e5e5e] dark:[&>*]:text-[#fff]">
        {bottomMenu.map((menu, i) => {
          if (i === 4)
            //   我的
            return (
              <button
                key={menu.title + i}
                className="w-[30px]"
                onClick={onClickUser}
              >
                <Image
                  className="dark:hidden"
                  src={`/images/icon/icon_light_${menu.title.toLowerCase()}.svg`}
                  width={28}
                  height={28}
                  sizes="28px"
                  loading="lazy"
                  alt={`link_${menu.title}`}
                />

                <Image
                  className="hidden dark:inline-block"
                  src={`/images/icon/icon_dark_${menu.title.toLowerCase()}.svg`}
                  width={28}
                  height={28}
                  sizes="28px"
                  loading="lazy"
                  alt={`link_${menu.title}`}
                />
                {t(menu.title)}
              </button>
            );
          return (
            <Link
              href={`/${menu.title.toLowerCase()}`}
              key={menu.title + i}
              className="w-[30px]"
            >
              <Image
                className="dark:hidden"
                src={`/images/icon/icon_light_${menu.title.toLowerCase()}.svg`}
                width={28}
                height={28}
                sizes="28px"
                loading="lazy"
                alt={"link" + t(menu.title)}
              />

              <Image
                className="hidden dark:inline-block"
                src={`/images/icon/icon_dark_${menu.title.toLowerCase()}.svg`}
                width={28}
                height={28}
                sizes="28px"
                loading="lazy"
                alt={"link" + t(menu.title)}
              />
              {t(menu.title)}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default MobileBottomMenu;
