// 頁面選單
import React from "react";
import { NAV_MENU, CRYPTO_CITY_OFFICIAL_URL } from "@/common/constant";
import Link from "next/link";

import AcademyButton from "./AcademyButton"; // 學院按鈕
import MobileAcademyButton from "./MobileAcademyButton";

const PageMenu = ({ t, type = null }) => {
  // 手機版
  if (type === "mobile") {
    return (
      <ul
        className="space-y-[2px] w-[200px] mx-auto text-bd1m text-black
      [&>li>*]:px-[30px] [&>li>*]:py-[10px] [&>li>*]:flex [&>li>*]:items-center [&>li>*]:gap-x-[20px] [&>li>*]:trans"
      >
        {Object.values(NAV_MENU)?.map((v, i) => {
          if (v === "SHOP")
            return (
              <li key={"mobile" + v} className="group">
                <a
                  href={CRYPTO_CITY_OFFICIAL_URL.CS}
                  key={v + i}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="top-bar hover-text"
                >
                  <span className="w-[6px] h-[6px] bg-black" />
                  {t(v)}
                </a>
              </li>
            );
          return (
            <li key={"mobile" + v} className="group">
              {v === "ACADEMY" ? (
                // 學院按鈕
                <MobileAcademyButton v={v} />
              ) : (
                <Link
                  href={`/${v.toLowerCase()}`}
                  className="top-bar hover-text"
                >
                  <span className="w-[6px] h-[6px] bg-black" />
                  {t(v)}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <ul
      className="flex items-center [&_a]:text-btn1b [&_a]:px-[17px] [&_a]:py-[10px] [&_a]:inline-block
            [&_a]:trans text-black"
    >
      {Object.values(NAV_MENU)?.map((v, i) => {
        // 加密市集
        if (v === "SHOP")
          return (
            <li key={v + i}>
              <a
                href={CRYPTO_CITY_OFFICIAL_URL.CS}
                target="_blank"
                rel="noreferrer noopener"
                className="top-bar hover:text-black7"
              >
                {t(v)}
              </a>
            </li>
          );
        if (v === "ACADEMY") {
          return (
            // 學院按鈕
            <AcademyButton key={v + i} page={t(v)} />
          );
        }
        // 其他
        return (
          <React.Fragment key={v + i}>
            <li>
              <Link
                href={`/${v.toLowerCase()}`}
                className="top-bar hover:text-black7"
              >
                {t(v)}
              </Link>
            </li>
            <li className="inline-block w-[.5px] h-[13px] border-l-1 border-black2 border-opacity-[.5]" />
          </React.Fragment>
        );
      })}
    </ul>
  );
};

export default PageMenu;
