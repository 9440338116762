// for socket url (判斷在不同環境的連結)
// ** pass in window.location.host
function getSocketUrl(host) {
  let socketUrl = "http://localhost:3900";
  if (host.endsWith("pubgame.tw")) {
    socketUrl = "https://cc-socket.pubgame.tw";
  } else if (host.endsWith("cryptocity.tw")) {
    socketUrl = "https://socket.cryptocity.tw";
  }

  return socketUrl;
}

export { getSocketUrl };
