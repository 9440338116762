"use client";
import useScrollDirection from "@/hook/useScrollDirection";
import { usePathname } from "next/navigation";

const Header = ({ children }) => {
  const scrollDirection = useScrollDirection(); // scroll 方向
  const pathname = usePathname();

  return (
    <>
      <header
        className={`sticky top-[0] h-[54px] z-[11] w-full px-[30px] py-[8px] border-b-1 border-black2 border-opacity-[.3] ulg:border-black1
        bg-white max-w-screen overflow-hidden usm:px-[15px] trans ${
          scrollDirection === "down" ? "ulg:top-[-60px]" : "ulg:top-[0]"
        } ${
          pathname === "/" || pathname === "/cn" ? "ulg:fixed ulg:left-0" : ""
        }`}
      >
        {/* 導覽列 */}
        {children}
        {/* <Nav locale={locale} /> */}
      </header>

      {/* 背景用 */}
      <div
        className={`trans fixed z-[10] top-[-150px] left-0 w-full  h-[200px] bg-white ${
          scrollDirection === "down" ? "ulg:top-[-200px]" : "ulg:top-[-150px]"
        }`}
      />
    </>
  );
};

export default Header;
