// 複製文字用
import { useDispatch } from "react-redux";
import { setCopyHint } from "@/slices/popSlice";

export default function useCopy() {
  const dispatch = useDispatch();

  const copy = async (text) => {
    dispatch(setCopyHint(true));
    await navigator.clipboard.writeText(text);
  };

  return copy;
}
