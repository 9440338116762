// 所有彈窗
"use client";
import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  popState,
  setAcademyModal,
  setIdleModal,
  setNotifyModal,
  setUserMenuModal,
  setSearchModal,
  setFullImageModal,
  setMenu,
  setCopyHint,
  setPodcastPlayer,
  setSpeakerModal,
} from "@/slices/popSlice";
import { useTranslation } from "react-i18next";
import { usePathname } from "next/navigation";
import { userState } from "@/slices/userSlice";

import SearchModal from "./SearchModal"; // 搜尋彈窗
import UserMenuModal from "./UserMenuModal"; // 使用者選單
import NotifyModal from "./NotifyModal"; // 通知彈窗
import IdleModal from "./IdleModal"; // 閒置彈窗
import AcademyModal from "./AcademyModal"; // 加密學院彈窗
import FullImageModal from "./FullImageModal"; // 新聞圖片滿版彈窗
import MobileMenuModal from "./MobileMenuModal"; // 手機版側邊選單
import CopyHint from "./CopyHint"; // 複製提示
import PodcastPlayer from "./PodcastPlayer"; // podcast 播放器
import MobileBottomMenu from "./MobileBottomMenu"; // 手機版置底選單
import LoginModal from "../login/LoginModal"; // 登入彈窗
import SpeakerIntroModal from "./SpeakerIntroModal";
import NewsProgressBar from "./NewsProgressBar"; // 文章進度條

const ModalsInner = ({ locale }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pathname = usePathname();
  const { user } = useSelector(userState);

  const {
    showSearchModal,
    showUserMenuModal,
    showNotifyModal,
    showIdleModal,
    showAcademyModal,
    showFullImageModal,
    showMenu,
    showCopyHint,
    podcastPlayer,
    showSpeakerModal,
    showLoginModal,
  } = useSelector(popState);

  // 關閉彈窗
  const handleModals = useCallback(() => {
    dispatch(setAcademyModal(false));
    dispatch(setIdleModal(false));
    dispatch(setNotifyModal(false));
    dispatch(setUserMenuModal(false));
    dispatch(setSearchModal(false));
    dispatch(setFullImageModal(false));
    dispatch(setMenu(false));
    dispatch(setSpeakerModal(false));
  }, [pathname]);

  useEffect(() => {
    handleModals();
  }, [pathname, showLoginModal]);

  return (
    <>
      {/* 搜尋彈窗 */}
      <SearchModal
        showSearchModal={showSearchModal}
        dispatch={dispatch}
        setSearchModal={setSearchModal}
        locale={locale}
        t={t}
      />

      {user?.uid ? (
        <>
          {/* 使用者選單 */}
          <UserMenuModal
            setUserMenuModal={setUserMenuModal}
            showUserMenuModal={showUserMenuModal}
            dispatch={dispatch}
            t={t}
          />

          {/* 通知彈窗 */}
          <NotifyModal
            showNotifyModal={showNotifyModal}
            dispatch={dispatch}
            setNotifyModal={setNotifyModal}
            locale={locale}
            t={t}
          />
        </>
      ) : (
        // 登入彈窗
        <LoginModal t={t} />
      )}

      {/* 閒置彈窗 */}
      <IdleModal
        locale={locale}
        showIdleModal={showIdleModal}
        dispatch={dispatch}
        setIdleModal={setIdleModal}
        t={t}
      />

      {/* 加密學院彈窗 */}
      <AcademyModal
        dispatch={dispatch}
        t={t}
        showAcademyModal={showAcademyModal}
        setAcademyModal={setAcademyModal}
      />

      {/* 手機版側邊選單 */}
      <MobileMenuModal
        t={t}
        dispatch={dispatch}
        setMenu={setMenu}
        showMenu={showMenu}
        locale={locale}
        setUserMenuModal={setUserMenuModal}
      />

      {/* 手機版置底選單 */}
      <MobileBottomMenu t={t} />

      {/* 複製提示 */}
      <CopyHint
        t={t}
        dispatch={dispatch}
        setCopyHint={setCopyHint}
        showCopyHint={showCopyHint}
      />

      {/* podcast 播放器 */}
      <PodcastPlayer
        t={t}
        dispatch={dispatch}
        setPodcastPlayer={setPodcastPlayer}
        podcastPlayer={podcastPlayer}
      />

      {/* 講者介紹 */}
      {pathname?.includes("/events/") && (
        <SpeakerIntroModal
          showSpeakerModal={showSpeakerModal}
          dispatch={dispatch}
          setSpeakerModal={setSpeakerModal}
          locale={locale}
          t={t}
        />
      )}

      {/* 新聞圖片全版彈窗 */}
      {pathname?.includes("/news/" || "/academy/" || "/events/") && (
        <FullImageModal
          showFullImageModal={showFullImageModal}
          setFullImageModal={setFullImageModal}
          dispatch={dispatch}
        />
      )}

      {/* 文章進度條 */}
      {pathname?.includes("/news/") && <NewsProgressBar locale={locale} />}
    </>
  );
};

export default ModalsInner;
