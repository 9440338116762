// 手機選單 學院按鈕
"use client";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Image from "next/image";
import Link from "next/link";

const MobileAcademyButton = ({ v }) => {
  const { t } = useTranslation();
  const [fold, setFold] = useState(true);

  return (
    <>
      <div
        className={`hover-text relative cp ${fold ? "" : "decoration-black"}`}
        onClick={() => setFold((prev) => !prev)}
      >
        <span className="w-[6px] h-[6px] bg-black" />
        {t(v)}
        <Image
          className={`absolute right-[20px] trans ${
            fold ? "" : "rotate-[180deg]"
          } dark:filter-white`}
          width={8}
          height={8}
          src="/images/icon/icon_menu_fold.svg"
          alt="fold"
        />
      </div>
      <div
        className={`flex-col gap-y-[20px] items-start text-left [&>*]:w-full
        [&>*]:pl-[26px] [&>*]:trans [&>*]:underline [&>*]:decoration-transparent
        ${fold ? "!hidden" : "flex"}`}
      >
        <Link href="/academy" className="hover:decoration-black">
          {t("CAMPUS")}
        </Link>
        <Link href="/knowledge-base" className="hover:decoration-black">
          {t("BASE")}
        </Link>
      </div>
    </>
  );
};

export default MobileAcademyButton;
