"use client";

import { usePathname } from "next/navigation";
import FooterComponent from "./footer/FooterComponent";

export default function Footer() {
  const pathname = usePathname();

  if (pathname === "/cn" || pathname === "/") return <></>;

  return (
    <footer
      className="mt-[80px] w-full px-[64px] pt-[60px] pb-[40px] bg-white max-w-full
      blb:px-[45px] ulg:mt-0 ulg:px-[30px] usm:!px-[15px]"
    >
      <div className="max-w-[1072px] mx-auto">
        <FooterComponent />
      </div>
    </footer>
  );
}
