// 選單 登入註冊按鈕
"use client";
import { setLoginModal } from "@/slices/popSlice";

const MenuLoginButton = ({ t, dispatch }) => {
  return (
    <button
      onClick={() => dispatch(setLoginModal(true))}
      className="mx-auto w-[200px] block text-center rounded-[40px] bg-black text-btn1 text-white
        py-[15px]"
    >
      {t("LOGIN")} ｜ {t("REGISTER")}
    </button>
  );
};

export default MenuLoginButton;
