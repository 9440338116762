import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { PODCAST_API_ROUTER_PATH } from "@/common/constant";

const initialState = {
  podcasts: { cn: [], tw: [] }, // podcast 原始資料
  pageIndex: 0,
  canFetch: true,
};

export const fetchPodcastThunk = createAsyncThunk(
  "data/fetchData",
  async ({ pageIndex, locale, type }) => {
    try {
      const { data } = await axios.get(
        PODCAST_API_ROUTER_PATH.getPodcastByPage +
          `?pageIndex=${pageIndex}&locale=zh-${locale}&pageSize=5`,
        {
          headers: {
            "X-LANG": "zh-" + locale,
          },
        }
      );
      const list = data.data;

      return { data: list, locale, type };
    } catch (error) {
      console.error(error);
    }
  }
);

export const podcastSlice = createSlice({
  name: "podcast",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPodcastThunk.fulfilled, (state, action) => {
      const { podcasts } = state;
      const { locale, data, type } = action.payload;
      if (type === "init") {
        state.podcasts = {
          cn: [],
          tw: [],
          [locale]: data,
        };
        state.pageIndex = 1;
      } else {
        state.podcasts = {
          ...podcasts,
          [locale]: podcasts[locale].concat(data),
        };
        state.pageIndex = state.pageIndex + 1;
      }
      state.canFetch = false;
    });
  },
});

export const podcastState = (state) => state.podcast;

export default podcastSlice.reducer;
