"use client";
import { useDispatch } from "react-redux";
import { setAcademyModal } from "@/slices/popSlice";

const AcademyButton = ({ page }) => {
  const dispatch = useDispatch();
  return (
    <>
      <li className="cp" onMouseEnter={() => dispatch(setAcademyModal(true))}>
        <span className="top-bar hover:text-black7 ">{page}</span>
      </li>
      <li className="inline-block w-[.5px] h-[13px] border-l-1 border-black2 border-opacity-[.5]" />
    </>
  );
};

export default AcademyButton;
