// 搜尋輸入筐
import Image from "next/image";

const SearchInput = ({ t, keyword, setKeyword, onSubmit }) => {
  const onKeyDown = (e) => {
    if (e.keyCode === 13) onSubmit();
  };

  return (
    <div
      className="border-1 border-black2 bg-white pr-[20px] rounded-[4px] overflow-hidden
        flex items-center space-x-[15px]"
    >
      <input
        className="w-full text-btn1 text-black9 placeholder:text-black3 pl-[30px] py-[18px] bg-white"
        placeholder={t("KEYWORD") + "..."}
        value={keyword}
        enterKeyHint="search"
        onChange={(e) => setKeyword(e.target.value)}
        onSubmit={onSubmit}
        onKeyDown={onKeyDown}
      />

      <Image
        className="cp"
        onClick={onSubmit}
        width={32}
        height={32}
        src="/images/icon/icon_search.svg"
        alt="search"
        loading="lazy"
      />
    </div>
  );
};

export default SearchInput;
