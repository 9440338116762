// 閒置彈窗廣告輪播
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Image from "next/image";

const PromoSwiper = ({ banners, type = "pc" }) => {
  if (banners && banners?.length > 0)
    return (
      <Swiper
        className="w-full h-full"
        loop={true}
        modules={[Autoplay]}
        spaceBetween={-1}
        slidesPerView={1}
        autoplay={{
          delay: 4000,
        }}
      >
        {banners?.map((banner, i) => (
          <SwiperSlide key={banner.id + i + "index-banner"}>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={banner?.link}
              className={`h-full block img-box rounded-[2px] overflow-hidden w-full after:content-none ${
                type === "pc" ? "" : "hidden ulg:block"
              }`}
            >
              <Image
                className="object-cover object-center"
                width={741}
                height={118}
                sizes="(max-width: 599px) 80vw, 680px"
                loading="lazy"
                src={banner?.image || "/images/news/default_related.svg"}
                alt={banner?.subject}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  return <></>;
};

export default PromoSwiper;
