import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cmOrigin: "",
  previousUrl: "",
};

export const originalURLSlice = createSlice({
  name: "originalURL",
  initialState,
  reducers: {
    changeCmOriginURL: (state, action) => {
      state.cmOrigin = action.payload;
    },
    changePreviousUrl: (state, action) => {
      state.previousUrl = action.payload;
    },
  },
});

export const originURL = (state) => state.originalURL;
export const { changeCmOriginURL, changePreviousUrl } =
  originalURLSlice.actions;
export default originalURLSlice.reducer;
