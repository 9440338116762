"use client";
import { useEffect, useState } from "react";
import { usePathname, useParams, useSearchParams } from "next/navigation";

import { useDispatch, useSelector } from "react-redux";
import notificationClient from "@/common/apiClient/notificationClient";
import authClient from "@/common/apiClient/authClient";
import anaClient from "@/common/apiClient/anaClient";
import { userLogin, userState, userMutate } from "@/slices/userSlice";
import io from "socket.io-client";
import {
  setNotification,
  setNewNotification,
  markOneNotificationAsRead,
  markAllNotificationAsRead,
  notificationState,
} from "@/slices/notificationSlice";
import { getSocketUrl } from "../../common/util";
import OriginURL from "@/components/layout/OriginURL";

let socket;

const Layout = () => {
  const dispatch = useDispatch();
  const { notification } = useSelector(notificationState);
  const { user } = useSelector(userState);
  const pathname = usePathname();
  const params = useParams();
  const searchParams = useSearchParams();

  const [googleClientId, setGoogleClientId] = useState(null);
  const [isGoogleOnLoad, setGoogleOnLoad] = useState(false);
  const [showGoogleLogin, setShowGoogleLogin] = useState(false); // 顯示 google提示

  // 取得 user 資訊
  const fetchUser = async () => {
    try {
      const data = await authClient.getUser();
      const result = await authClient.getUserWallet();

      if (data?.user && result) {
        const user = Object.assign(data?.user, result);
        dispatch(userLogin(user));
      }
    } catch (error) {
      // console.error(error);
      processGoogOneTap();
    }
  };

  // 建立 socket
  const createSocket = async () => {
    const socketUrl = getSocketUrl(window.location.host);
    socket = io(socketUrl, { transports: ["websocket"] });

    socket.on("connect", () => {
      console.log("connected:" + user.uid);
      // join user
      socket.emit("joinUser", { uid: user.uid, sites: ["cs"] });
    });

    // receive notify (新訊息)
    socket.on("notify", async (payload) => {
      console.log("notfiy payload:", payload);

      dispatch(setNewNotification(payload));

      if (payload.userChange) {
        // 從其他裝置變更會員資料，所以這邊也要及時反應
        // 改變 user資料
        // 錢包變更
        if (payload.userChange.walletAddress) {
          const updatedUser = JSON.parse(JSON.stringify(user));
          if (payload.userChange.action === "add")
            updatedUser.wallets.push(payload.userChange);
          else {
            const newWallets = updatedUser.wallets.filter(
              (w) => w?.walletAddress !== payload.userChange.walletAddress
            );
            updatedUser.wallets = newWallets;
          }
          dispatch(userMutate(updatedUser));
        } else {
          dispatch(userMutate(payload.userChange));
        }

        // update user data in session
        // 可能是 cc-app 資料改變，則 cc/cs 必須同步
        // TODO 將原本的 api 搬到 cc-api
        await authClient.putUser(payload);
      }
    });

    // receive notify-read (接收變更訊息讀取狀態)
    socket.on("notify-read", (payload) => {
      console.log("notify-read:", payload);

      // 標記為已讀
      if (payload?.personalIds?.length > 1) {
        dispatch(markAllNotificationAsRead());
      } else {
        dispatch(markOneNotificationAsRead(payload?.personalIds[0]));
      }
    });
    socket.on("disconnect", (reason) => {
      console.log("disconnect:", reason);
    });
  };

  // 拿通知訊息資料
  const getNotifications = async () => {
    try {
      const result = await notificationClient.getNotifications();
      if (result && result !== notification) dispatch(setNotification(result));
    } catch (e) {
      console.error(e);
    }
  };

  // 建立 access log
  const handleAccessLog = () => {
    let path;
    // 以下路徑需特別處理
    const paramMapping = {
      newsSlug: "[newsSlug]",
      academySlug: "[academySlug]",
      columnId: "[columnId]",
      eventsId: "[eventsId]",
      flashId: "[flashId]",
      word: "[word]",
      projectSlug: "[projectSlug]",
      keyword: "[keyword]",
    };

    Object.keys(paramMapping).forEach((key) => {
      if (params[key]) {
        path = pathname?.replace(params[key], paramMapping[key]);
      }
    });

    if (!path) path = pathname;

    const queryParams = {};
    for (const [key, value] of searchParams.entries()) {
      queryParams[key] = value;
    }

    const accessLog = {
      pathName: path,
      path: pathname,
      params: JSON.stringify(queryParams),
      source: queryParams?.source,
    };
    anaClient.createAccessLog(accessLog);
  };

  // 判斷 google clientId 用
  const handleGoogleUrl = () => {
    const host = window.location.host;
    let googleClientId =
      "539472462227-e7lhd75oqlkmrj5icgn5s4g3d43vsp24.apps.googleusercontent.com";
    if (host.endsWith("pubgame.tw")) {
      googleClientId =
        "539472462227-e7lhd75oqlkmrj5icgn5s4g3d43vsp24.apps.googleusercontent.com";
    } else if (host.endsWith("cryptocity.tw")) {
      googleClientId =
        "431179767901-76jf3fa00gefqdoodpmin7vbr0jbute5.apps.googleusercontent.com";
    }
    setGoogleClientId(googleClientId);
  };

  const loadScript = (src) =>
    new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) return resolve();
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve();
      script.onerror = (err) => reject(err);
      document.body.appendChild(script);
      setShowGoogleLogin(true);
    });

  // 處理 google 登入提示
  const processGoogOneTap = async () => {
    try {
      if (!user?.uid && typeof google === "undefined" && !isGoogleOnLoad) {
        setGoogleOnLoad(true);
        const src = "https://accounts.google.com/gsi/client";
        await loadScript(src);
      }
    } catch (error) {
      // 只印出 error, 不 alert
      console.error(error);
    }
  };

  useEffect(() => {
    if (!user?.uid) fetchUser();
    handleGoogleUrl();
  }, []);

  useEffect(() => {
    // 有 user時建立 socket連線
    // 若 user 有值，在 socket 中 join uid
    if (user?.uid) {
      getNotifications();
      createSocket();
    } else if (socket) {
      // 會員登出後切斷連結
      socket.disconnect();
    }

    return () => {
      socket?.disconnect();
    };
  }, [user?.uid]);

  useEffect(() => {
    handleAccessLog();
  }, [pathname]);

  return (
    <>
      <OriginURL />

      {!user?.uid && showGoogleLogin && (
        <div
          className="flex items-start gap-x-[12px] umd:hidden"
          style={{
            position: "fixed",
            top: "65px",
            right: "20px",
            zIndex: 50,
          }}
        >
          <div
            id="g_id_onload"
            className="g_id_signin overflow-hidden rounded-[3px]"
            data-client_id={googleClientId}
            data-login_uri={`/api/v1/auth/callback/google-one-tap`}
            data-auto_select="true"
            data-width="240"
          />
          {/* 關閉用 */}
          <button
            className="relative bottom-[8px] text-[1.2rem]"
            onClick={() => setShowGoogleLogin(false)}
          >
            ×
          </button>
        </div>
      )}
    </>
  );
};

export default Layout;
