// 手機選單 app 下載區塊
import { CRYPTO_CITY_OFFICIAL_URL } from "@/common/constant";
import Image from "next/image";

const MenuAppWrapper = ({ t }) => {
  return (
    <div className="py-[24px] border-t-1 border-b-1 border-black2">
      <a
        href={CRYPTO_CITY_OFFICIAL_URL.APP}
        target="_blank"
        rel="noreferrer noopener"
      >
        <p className="text-bd2b text-black">APP Download</p>
        <p className="text-bd4n text-black9 whitespace-nowrap">{t("APP")}</p>

        <div className="mt-[20px] flex gap-x-[24px]">
          <div className="img-box w-[76px] h-[76px] flex-shrink-0">
            <Image
              width={76}
              height={76}
              loading="lazy"
              src="/images/icon/icon_app.svg"
              alt="app"
              sizes="70px"
            />
          </div>

          <div className="flex flex-col justify-between [&>div]:after:content-none">
            <div className="img-box w-[119px] h-[30px]">
              <Image
                className="dark:filter-white"
                width={119}
                height={30}
                loading="lazy"
                src="/images/icon/icon_apple.svg"
                alt="apple"
                sizes="115px"
              />
            </div>
            <div className="img-box w-[119px] h-[30px]">
              <Image
                width={119}
                height={30}
                loading="lazy"
                src="/images/icon/icon_android.svg"
                alt="android"
                sizes="115px"
              />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default MenuAppWrapper;
