import Image from "next/image";
import { useDispatch } from "react-redux";
import { setSpeakerModal } from "@/slices/popSlice";

const EventSpeaker = ({ item, introModal }) => {
  const dispatch = useDispatch();
  const image = item?.image ? item?.image : "/images/news/default_related.svg";
  const name = item?.name ? item?.name : "name";
  const unit = item?.unit ? item?.unit : "unit";
  const title = item?.title ? item?.title : "title";

  return (
    <div
      className={`speaker-item group text-center flex cursor-pointer
            ${
              introModal
                ? "gap-[35px]"
                : "gap-[5px] flex-col bg-white w-[270px] h-[270px] justify-center items-center"
            }`}
      onClick={() => dispatch(setSpeakerModal(item))}
    >
      <div className="img-box aspect-square rounded-[50%] overflow-hidden w-[118px] pointer-events-none">
        <Image
          className="speaker-img hover-img"
          width={118}
          height={118}
          src={image || "/images/layout/default_head.png"}
          alt="speaker_image"
          sizes="118px"
          loading="lazy"
        />
      </div>
      <div
        className={`flex flex-col gap-[5px] ${
          introModal
            ? "justify-center align-baseline items-start"
            : "w-full [&>p]:whitespace-nowrap [&>p]:text-ellipsis [&>p]:overflow-hidden"
        }`}
      >
        <p className="text-bd2b">{name}</p>
        <p className="text-bd4n">{unit}</p>
        <p className="text-bd4n">{title}</p>
      </div>
    </div>
  );
};

export default EventSpeaker;
