import FooterLink from "./FooterLink"; // 頁面連結
import FooterSocial from "./FooterSocial"; // 社群連結

const FooterComponent = () => {
  return (
    <>
      {/* logo + 頁面連結 */}
      <FooterLink />

      <div className="between-center usm:flex-col usm:gap-y-[35px]">
        <p className="text-bd3n text-black  usm:order-[0]">
          Copyright © 2024 Crypto City All Rights Reserved
        </p>

        {/* 社群連結 */}
        <FooterSocial />
      </div>
    </>
  );
};

export default FooterComponent;
