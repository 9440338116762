import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/layout/Footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/layout/Header.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/layout/Layout.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/layout/nav/AcademyButton.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/layout/nav/MobileAcademyButton.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/layout/nav/NavFunction.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/modal/ModalsInner.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/components/news/GoTop.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/StoreProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/components/TranslationsProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/var/www/cc-web-v4/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/styles/global.scss");
