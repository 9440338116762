import { io } from "socket.io-client";
import { getSocketUrl } from "@/common/util";

// 更新 其他同帳號裝置 通知讀取狀態
async function sendReadStatus(payload) {
  const socketUrl = getSocketUrl(window.location.host);
  const socket = io(socketUrl, { transports: ["websocket"] });
  socket.on("connect", () => {
    try {
      // payload example: { uid: 'G12345', personalIds: [ 10, 11 ], publicIds, [] }
      socket.emit("newEventRead", payload);

      // 因為發送訊息為一次性發送，所以發完就關連結
      setTimeout(() => {
        socket.disconnect();
      }, 10000);
    } catch (err) {
      console.error(err);
    }
  });
}

// 發新的通知
async function sendNewNotify(payload) {
  const socketUrl = getSocketUrl(window.location.host);
  const socket = io(socketUrl, { transports: ["websocket"] });

  socket.on("connect", () => {
    try {
      socket.emit("newEvent", payload);
      // 因為發送訊息為一次性發送，所以發完就關連結
      setTimeout(() => {
        socket.disconnect();
      }, 1000);
    } catch (err) {
      console.error(err);
    }
  });
}

export { sendReadStatus, sendNewNotify };
