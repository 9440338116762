import momentTZ from "moment-timezone";
import moment from "moment";

const locale = "zh-tw";

require(`moment/locale/${locale}`);
moment.locale(locale); // apply it to moment
momentTZ.updateLocale(locale, moment.localeData()._config); // copy locale to moment-timezone
momentTZ.locale(locale); // apply it to moment-timezone

export default momentTZ;
