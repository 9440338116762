"use client";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { usePathname } from "next/navigation";
import { changeCmOriginURL, changePreviousUrl } from "@/slices/originURLSlice";
const OriginURL = () => {
  const dispatch = useDispatch();
  const pathname = usePathname();

  const handleOrigin = () => {
    if (typeof window !== "undefined") {
      const origin = window.location.href;
      if (origin.includes("dev")) {
        dispatch(changeCmOriginURL("https://cc-member.local.dev:3003/"));
      } else if (origin.includes("pubgame")) {
        dispatch(changeCmOriginURL("https://cc-member.pubgame.tw/"));
      } else {
        dispatch(changeCmOriginURL("https://member.cryptocity.tw/"));
      }
    }
  };

  // 紀錄 previous url 用
  const handleRouteChange = () => {
    if (typeof window !== "undefined" && pathname === "/flash") {
      dispatch(changePreviousUrl(pathname));
    }
  };

  useEffect(() => {
    handleOrigin();
  }, []);

  useEffect(() => {
    handleRouteChange();
  }, [pathname]);

  return <></>;
};

export default OriginURL;
