// 閒置彈窗 (閒置五分鐘開啟)
import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useIdleTimer } from "react-idle-timer";

import { revalidatePage } from "@/app/actions";
import {
  CRYPTO_CITY_OFFICIAL_URL,
  PROMO_BANNER_POSITION_CODE,
} from "@/common/constant";

import promoClient from "@/common/apiClient/promoClient";
import ModalPopular from "../search/ModalPopular"; // 熱門新聞
import FooterSocial from "../layout/footer/FooterSocial"; // 社群連結
import PromoSwiper from "../promo/PromoSwiper"; // 廣告欄位

const IdleModal = ({ dispatch, showIdleModal, t, locale, setIdleModal }) => {
  //   const [state, setState] = useState("Active"); // 當前狀態 (idle,active)
  //   const [eventInfo, setEventInfo] = useState("No event"); // 使用者行為
  //   const [remaining, setRemaining] = useState(0); // 閒置前倒數
  //   const [prompt, setPrompt] = useState(""); // 提示
  const router = useRouter();

  const [pcBanner, setPcBanner] = useState([]); // 桌機 banner
  const [mbBanner, setMbBanner] = useState([]); // 手機 banner
  const [showBanner, setShowBanner] = useState(false);

  // 取得閒置彈窗廣告
  const getAdBanner = async () => {
    try {
      const resultPc = await promoClient.getPromoBanner(
        PROMO_BANNER_POSITION_CODE.IDLE_PC
      );
      const resultMb = await promoClient.getPromoBanner(
        PROMO_BANNER_POSITION_CODE.IDLE_MB
      );

      setPcBanner(resultPc?.banners);
      setMbBanner(resultMb?.banners);
    } catch (e) {
      console.error(e);
    }
  };

  // 閒置 開閒置彈窗
  const onIdle = () => dispatch(setIdleModal(true));

  //   動作
  //   const onActive = () => {
  //     setState("Active");
  //     setPrompt("");
  //   };

  //   使用者行為
  //   const onAction = (event) => {
  //     if (event) {
  //       setEventInfo(`事件 ${event.type} 发生在 ${new Date()}`);
  //     }
  //   };

  //   提示
  //   const onPrompt = () => {
  //     setPrompt("即将进入空闲状态");
  //   };

  const { getRemainingTime } = useIdleTimer({
    onIdle, // 閒置
    // onActive, // 非閒置
    // onAction, // 有行為
    // onPrompt, // 提示
    timeout: 300_000, // 閒置時間 五分鐘
    promptBeforeIdle: 5_000, // 閒置前提醒
    throttle: 1_000, // 計算基礎
  });

  // 處理 banner 顯示
  const handleBanner = () => {
    if (showIdleModal) setShowBanner(true);
    else setTimeout(() => setShowBanner(false), 500);
  };

  // 關閉彈窗 重整
  const handleClose = () => {
    // 頁面更新
    revalidatePage();
    window.location.reload();
    dispatch(setIdleModal(false));
  };

  useEffect(() => {
    getAdBanner();
  }, []);

  useEffect(() => {
    handleBanner();
  }, [showIdleModal]);

  return (
    <div className={`idle-modal modal ${showIdleModal ? "act" : ""}`}>
      {/* 關閉用背景 */}
      <div className="close-bg" onClick={handleClose} />

      <div className="inner max-w-[850px]">
        <div
          className="flex items-center space-x-[60px] pl-[54px] pr-[28px] py-[40px] border-b-1 border-black2 [@media(max-width:900px)]:justify-between [@media(max-width:900px)]:space-x-[30px]
          [@media(max-width:900px)]:px-[35px] umd:flex-col umd:!space-x-0 umd:gap-y-[23px] usm:!px-[20px]"
        >
          <Link
            href="/"
            className="img-box cp w-[212px] max-w-[212px] after:content-none [&>img]:object-contain group aspect-[212/48] [@media(max-width:900px)]:w-[145px] umd:!w-[212px]"
          >
            <Image
              width={212}
              height={48}
              src={`/images/layout/logo.svg`}
              alt="logo"
              sizes="210px"
              loading="lazy"
              className="dark:hidden trans group-hover:filter-black"
            />
            <Image
              width={212}
              height={48}
              src={`/images/layout/logo_dark.svg`}
              alt="logo"
              sizes="210px"
              loading="lazy"
              className="hidden dark:block group-hover:filter-white"
            />
          </Link>

          <div className="flex items-center space-x-[17px] flex-shrink-0">
            <p className="text-bd4n text-black7 7 flex-shrink-0 umd:text-center umd:w-full">
              {t("IDLE")}
            </p>
            {/* 關閉按鈕 */}
            <button
              className="trans text-btn1 flex-shrink-0 text-white px-[22px] py-[10px] border-1 border-jblack
            rounded-[40px] bg-jblack hover:text-black hover:bg-white umd:hidden"
              onClick={handleClose}
            >
              {t("CLOSE")}
            </button>
          </div>
        </div>

        <div className="px-[54px] py-[25px] umd:px-[35px] umd:pb-[35px] usm:!px-[20px] usm:pt-[20px]">
          {/* 熱門新聞 */}
          <ModalPopular locale={locale} />
          {/* 廣告欄位 pc + mb */}
          {showBanner && (
            <>
              {pcBanner?.length > 0 && (
                <div className="ulg:hidden h-[118px]">
                  <PromoSwiper banners={pcBanner} type="pc" />
                </div>
              )}
              {mbBanner?.length > 0 && (
                <div className="hidden ulg:block h-[118px]">
                  <PromoSwiper banners={mbBanner} />
                </div>
              )}
            </>
          )}
          {/* 關閉按鈕 */}
          <button
            onClick={handleClose}
            className="hidden umd:block w-full bg-black mt-[30px] text-white text-center py-[13px] rounded-[40px]"
          >
            {t("CLOSE")}
          </button>
        </div>

        <div className="px-[54px] py-[20px] bg-black1 dear:bg-dblack1 between-center umd:hidden">
          <div className="space-x-[8px] flex items-center">
            <span className="text-bd4b text-black9 mr-[10px]">
              {t("FOLLOW")}
            </span>
            {/* 社群連結 */}
            <FooterSocial />
          </div>

          {/* app連結 */}
          <div className="center-center space-x-[12px] [&>a]:trans">
            <a
              href={CRYPTO_CITY_OFFICIAL_URL.APPLE}
              className="hover:opacity-[.7]"
            >
              <Image
                width={127}
                height={44}
                src="/images/index/apple_store.png"
                alt="app_store"
                loading="lazy"
                sizes="120px"
              />
            </a>
            <a
              href={CRYPTO_CITY_OFFICIAL_URL.GOOGLE}
              className="hover:opacity-[.7]"
            >
              <Image
                width={135}
                height={44}
                src="/images/index/google_store.png"
                alt="google_play"
                loading="lazy"
                sizes="135px"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdleModal;
