// 圖片擷取彈窗

import React from "react";
import ReactCrop from "react-image-crop";

// eslint-disable-next-line react/display-name
const ImageCropModal = React.forwardRef(
  (
    {
      showImageModal,
      image,
      crop,
      setCrop,
      setCompletedCrop,
      scale,
      setScale,
      onConfirmCrop,
      onCancelCrop,
      t,
    },
    ref
  ) => {
    if (showImageModal && image) {
      return (
        <>
          <div id="image-crop-modal-overlay"></div>
          <div id="image-crop-modal">
            <div className="image-crop-modal-inner">
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={1}
              >
                <img
                  ref={ref}
                  src={image}
                  alt="image crop"
                  style={{
                    transform: `scale(${scale})`,
                  }}
                />
              </ReactCrop>
              <div className="slider">
                <input
                  type="range"
                  min="0.1"
                  max="2"
                  step="0.1"
                  value={scale}
                  onChange={(e) => setScale(e.target.value)}
                />
              </div>
              <div className="btns-box flex justify-center items-center space-x-[25px] pb-[20px]">
                <button
                  className="w-[105px] rounded-[49px] text-[#fff] opacity-[.6] bg-[#c7c7c7] text-bd4b py-[10px]"
                  onClick={onCancelCrop}
                >
                  {t("CANCEL")}
                </button>
                <button
                  className="w-[105px] rounded-[49px] text-[#fff] bg-[#29afcb] text-bd4b py-[10px]"
                  onClick={onConfirmCrop}
                >
                  {t("CONFIRM")}
                </button>
              </div>
            </div>
          </div>
        </>
      );
    }

    return <></>;
  }
);
export default ImageCropModal;
