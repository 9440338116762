import { createRef, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { userLogin, userState } from "@/slices/userSlice";
import useDebounceEffect from "../../common/reactImageCrop/useDebounceEffect";
import canvasPreview from "../../common/reactImageCrop/canvasPreview";
import Resizer from "react-image-file-resizer";
import ImageCropModal from "../modal/ImageCropModal";
import authClient from "@/common/apiClient/authClient";

const LoginAvatar = ({ image, setImage, setLoginStep, dispatch, t, skip }) => {
  const { user } = useSelector(userState);
  const avatarRef = createRef();
  const previewCanvasRefAvatar = createRef(); // 預覽圖
  const fileRef = useRef(); // 檔案

  const [showImageModal, setShowImageModal] = useState(false);
  // for ReactCrop
  const [scale, setScale] = useState(1);

  // 擷取範圍
  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  const [completedCrop, setCompletedCrop] = useState(null);

  // 處理預覽canvas
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        avatarRef.current &&
        previewCanvasRefAvatar.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          avatarRef.current,
          previewCanvasRefAvatar.current,
          completedCrop,
          scale
        );
      }
    },
    100,
    [
      completedCrop,
      scale,
      avatarRef,
      previewCanvasRefAvatar,
      previewCanvasRefAvatar,
    ]
  );

  // 在選取照片範圍彈窗按「確認」
  const onConfirmCrop = () => {
    setShowImageModal(false);
  };

  // 在選取照片範圍彈窗按「取消」
  const onCancelCrop = () => {
    setShowImageModal(false);
    setCompletedCrop(null);
  };

  // 預處理檔案大小
  async function resizeFile(originalFile, format) {
    Resizer.imageFileResizer(
      originalFile,
      800, // maxWidth
      800, // maxHeight
      format, // format of the resized new image
      100, // quality
      0, // rotation
      (uri) => {
        const resizedURL = URL.createObjectURL(uri);
        setImage(resizedURL);
      },
      "file" // outputType
    );
  }

  // 檔案變更
  function onFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      // 上傳限制為 5MB
      if (e.target.files[0].size > 5 * 1024 * 1024) {
        e.preventDefault();
        alert(t("LIMIT"));
        return;
      }
      let format;
      if (e.target.files[0].type === "image/jpg") {
        format = "JPG";
      } else if (e.target.files[0].type === "image/png") {
        format = "PNG";
      } else {
        format = "JPEG";
      }
      resizeFile(e.target.files[0], format);

      // 讓使用者可以選取照片範圍
      setShowImageModal(true);

      // 擷取範圍
      setCrop({
        unit: "px", // Can be 'px' or '%'
        x: 0,
        y: 0,
        width: 200,
        height: 200,
      }); // Makes crop preview update between images

      if (!completedCrop) {
        setCompletedCrop({
          unit: "px", // Can be 'px' or '%'
          x: 0,
          y: 0,
          width: 200,
          height: 200,
        }); // Makes crop preview update between images
      }

      // 讀檔
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage(reader.result.toString() || "");
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  // 儲存頭像
  const onSaveImage = async (canvasRef, fileRef, type) => {
    // 沒有換頭像跳步驟 6
    if (!canvasRef.current) {
      setLoginStep(6);
      return;
    }

    // 上傳
    canvasRef.current.toBlob(async (blob) => {
      const formData = new FormData();
      formData.set(
        type === "avatar" ? "image" : "banner",
        blob,
        "filename.png"
      );
      try {
        const result = await authClient.updateImage(formData, type);
        if (result) {
          setLoginStep(6);
          dispatch(userLogin({ ...user, image: result.url }));
          fileRef.current.value = null;
        }
      } catch (e) {
        console.error(e);
      }
    });
  };

  return (
    <>
      <div className="login-box">
        <p className="text-h3 text-center text-[#141414]">{t("PICK")}</p>
        <p className="text-bd1m text-center text-[#393939] text-opacity-[.8] my-[15px]">
          {t("UPLOAD")}
        </p>
        <div className="mx-auto w-[225px] relative mt-[30px] mb-[40px]  usx:mb-[30px]">
          <label className="cp">
            {completedCrop ? (
              <canvas
                id="canvas3"
                className="rounded-[50%] w-[225px] h-[225px] usx:w-[125px] uxs:h-[180px] object-cover"
                ref={previewCanvasRefAvatar}
              />
            ) : (
              <img
                src={image || "/images/login/default.svg"}
                alt="avatar"
                className={`w-[225px] h-[225px] ${
                  image ? "rounded-[50%]" : ""
                } mx-auto overflow-hidden object-contain object-center uxs:w-[180px] uxs:h-[180px]`}
              />
            )}
            <img
              src="/images/login/add.svg"
              alt="add"
              className="absolute bottom-0 right-[10px]"
            />
            <input
              type="file"
              className="hidden"
              accept=".jpg,.png,.jpeg,.gif"
              ref={fileRef}
              onChange={(e) => {
                onFileChange(e);
              }}
            />
          </label>
        </div>
        <button
          onClick={() => onSaveImage(previewCanvasRefAvatar, fileRef, "avatar")}
          className="login-continue"
        >
          {t("NEXT_STEP")}
        </button>
        <button
          onClick={skip}
          className="text-bd4b w-full mt-[20px] mx-auto py-[16px] text-[#c7c7c7] bg-[#f7f7f8] text-opacity-[.8] rounded-[40px]"
        >
          {t("SKIP")}
        </button>
      </div>

      {/* 圖片截取 */}
      <ImageCropModal
        showImageModal={showImageModal}
        image={image}
        crop={crop}
        setCrop={setCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        setScale={setScale}
        onConfirmCrop={onConfirmCrop}
        onCancelCrop={onCancelCrop}
        ref={avatarRef}
        t={t}
      />
    </>
  );
};

export default LoginAvatar;
