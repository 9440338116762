// 使用者選單 連結
import { USER_MENU_LINK } from "@/common/constant";

const UserMenuLink = ({ t, cmOrigin }) => {
  return (
    <div className="pb-[20px] mb-[20px] border-dashed border-b-1 border-black3 ">
      <ul className="space-y-[8px]">
        {USER_MENU_LINK.map((v, i) => (
          <li key={v.title + i}>
            <a
              className="w-full inline-block px-[24px] py-[10px] trans text-btn1 rounded-[4px] overflow-hidden text-black
              hover:bg-black hover:text-white trans"
              href={cmOrigin + "account-setting" + v.link}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t(v.title)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserMenuLink;
