// podcast 播放
import { useRef } from "react";
import Image from "next/image";
import ReactAudioPlayer from "react-audio-player";

const PodcastPlayer = ({ t, dispatch, podcastPlayer, setPodcastPlayer }) => {
  const audioRef = useRef(null);

  if (podcastPlayer)
    return (
      <div className="podcast-player slide-up ulg:hidden trans fixed w-[90vw] z-[11] left-[50%] translate-x-[-50%] bottom-[-1px]">
        <div className="text-right">
          {/* 關閉按鈕 */}
          <button
            className="text-bd4n text-white px-[33px] py-[7px] rounded-t-[6px] backdrop-filter-[10px] bg-[#1e1e1e] dark:bg-[#F7F7F8]
            bg-opacity-[.5]"
            onClick={() => {
              if (audioRef.current) audioRef.current.audioEl.current.pause();

              dispatch(setPodcastPlayer(false));
            }}
          >
            {t("CLOSE")}
          </button>

          <div className="flex w-full bg-white items-center gap-x-[20px] border-black1 border-1 border-b-0 rounded-t-l-[2px] pr-[30px]">
            {/* podcast 封面 */}
            <div className="img-box w-[100px] h-[100px] rounded-[2px] overflow-hidden flex-shrink-0">
              <Image
                width={100}
                height={100}
                src={
                  podcastPlayer?.trackImg ||
                  "/images/podcast/default_podcast.png"
                }
                alt={podcastPlayer?.title || "podcast_cover"}
                loading="lazy"
              />
            </div>

            <div className="text-left flex-grow py-[5px]">
              <p className="text-bd3b text-black9">加密吧 CRYPTO BAR</p>
              {/* podcast 標題 */}
              <p className="text-h6 leading-[1] text-black line-clamp-1 mt-[5px] mb-[12px]">
                {podcastPlayer?.title}
              </p>
              <ReactAudioPlayer
                ref={audioRef}
                src={podcastPlayer?.episodeUrl}
                autoPlay
                controls
                className="h-[28px] w-full"
              />
            </div>
          </div>
        </div>
      </div>
    );

  return <></>;
};

export default PodcastPlayer;
