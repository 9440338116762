// 購物車相關
import apiClient from "./apiClient";

class AnaClient {
  // 新增 accessLog
  createAccessLog = (accessLog) =>
    apiClient.post("/ana/access-log", { accessLog });

  createNewsExtendLog = (newsExtendLog) =>
    apiClient.post("/ana/news-extend-log", newsExtendLog);

  // 建立 news read
  createNewsRead = (newsId) =>
    apiClient.post("/ana/news-read", {
      newsId,
    });
}

const anaClient = new AnaClient();

export default anaClient;
