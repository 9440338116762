import { useEffect } from 'react';

function useDebounceEffect(
    fn,
    waitTime,
    deps = [] // DependencyList
) {
    useEffect(() => {
        const t = setTimeout(() => {
            fn.apply(undefined, deps);
        }, waitTime);

        return () => {
            clearTimeout(t);
        };
    }, deps);
}

export default useDebounceEffect;
