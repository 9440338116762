// 搜尋彈窗
import { useState } from "react";
import { useRouter } from "next/navigation";
import dynamic from "next/dynamic";

import SearchInput from "../search/SearchInput"; // 搜尋輸入匡
import ModalPopular from "../search/ModalPopular"; // 彈窗用熱門新聞

const SearchHotWord = dynamic(() => import("../search/SearchHotWord")); //熱門關鍵字

const SearchModal = ({
  showSearchModal,
  dispatch,
  t,
  locale,
  setSearchModal,
}) => {
  const router = useRouter();
  const [keyword, setKeyword] = useState(""); // 搜尋關鍵字

  //   送出
  const onSubmit = () => {
    if (keyword.trim() === "") return;
    reset();
    router.push(`/search/${encodeURIComponent(keyword)}`);
  };

  //   重置清空
  const reset = () => {
    dispatch(setSearchModal(false));
    setKeyword("");
  };

  return (
    <div className={`search-modal modal ${showSearchModal ? "act" : ""}`}>
      {/* 關閉用 */}
      <div
        className="close-bg"
        onClick={() => dispatch(setSearchModal(false))}
      />

      <div className="inner max-w-[959px] p-[70px] ulg:px-[30px] ulg:py-[35px] usm:!px-[20px]">
        {/* 搜尋輸入匡 */}
        <SearchInput
          onSubmit={onSubmit}
          t={t}
          keyword={keyword}
          setKeyword={setKeyword}
        />

        {/* 熱門關鍵字 */}
        <SearchHotWord reset={reset} />

        {/* 熱門新聞 */}
        <ModalPopular locale={locale} />

        <button
          onClick={() => dispatch(setSearchModal(false))}
          className="hidden umd:block w-full bg-black mt-[15px] text-white text-center py-[13px] rounded-[40px]"
        >
          {t("CLOSE")}
        </button>
      </div>
    </div>
  );
};

export default SearchModal;
