import { configureStore } from "@reduxjs/toolkit";
import popReducer from "../slices/popSlice";
import originURLReducer from "../slices/originURLSlice";
import podcastReducer from "../slices/podcastSlice";
import userReducer from "../slices/userSlice";
import notificationReducer from "../slices/notificationSlice";

export const store = configureStore({
  reducer: {
    pop: popReducer,
    originalURL: originURLReducer,
    podcast: podcastReducer,
    user: userReducer,
    notification: notificationReducer,
  },
});
