// 倒數計時用
import { useState, useRef, useEffect } from 'react';

export default function useCountDown() {
    const timeRef = useRef(null); // 計時器
    const [second, setSecond] = useState();
    const [canRefresh, setCanRefresh] = useState(true); // 能否重整

    // 倒數
    const countDown = (sec) => {
        setCanRefresh(false);
        setSecond(sec);
        timeRef.current = setInterval(() => {
            setSecond((prev) => prev - 1);
        }, 1000);
    };

    // reset
    useEffect(() => {
        if (second === 0) {
            clearInterval(timeRef.current);
            setCanRefresh(true);
            setSecond(0);
        }
    }, [second]);

    return [{ second, canRefresh }, countDown];
}
