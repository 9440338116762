// 選單廣告 banner
import { useState, useEffect } from "react";
import promoClient from "@/common/apiClient/promoClient";
import Image from "next/image";
import { PROMO_BANNER_POSITION_CODE } from "@/common/constant";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const MenuBanner = () => {
  const [banners, setBanners] = useState([]); // 廣告 banner

  // 取得廣告 banner
  const getBanners = async () => {
    try {
      const result = await promoClient.getPromoBanner(
        PROMO_BANNER_POSITION_CODE.MB_MENU_TOP
      );
      setBanners(result?.banners);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  if (banners?.length > 0)
    return (
      <Swiper
        modules={[Autoplay]}
        centeredSlides={true} // 居中顯示當前 slide
        slidesPerView={1}
        loop={true}
        direction={"horizontal"} // 水平方向
        freeMode={true}
        autoplay={{
          delay: 4000,
        }}
      >
        {banners?.map((banner, i) => (
          <SwiperSlide key={banner?.id + "banner" + i}>
            <a
              href={banner?.link}
              target="_blank"
              rel="noreferrer noopener"
              className="w-full block img-box aspect-[224/74] rounded-[2px] overflow-hidden after:content-none"
            >
              {/* banner 圖片 */}
              <Image
                width={224}
                height={74}
                src={banner?.image || "/images/news/default_related.svg"}
                alt={banner?.subject}
                loading="lazy"
                sizes="235px"
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    );

  return <></>;
};

export default MenuBanner;
