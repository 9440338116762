// 新聞文章 進度條
"use client";
import { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "next/navigation";
import { popState, setNewsProgress, setCatalog } from "@/slices/popSlice";
import { usePathname } from "next/navigation";

import useCopy from "@/hook/useCopy";
import Image from "next/image";

const NewsProgressBar = ({ locale }) => {
  const { academySlug, newsSlug } = useParams();
  const dispatch = useDispatch();
  const copy = useCopy();
  const pathname = usePathname();

  const { showNewsProgress, newsBar, showCatalog } = useSelector(popState);
  const [y, setY] = useState(null);

  // 處理Scroll
  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY;

    if (scrollY > 150 && pathname.includes("news/")) {
      if (y > scrollY) {
        dispatch(setNewsProgress(false));
      } else if (y < scrollY) {
        dispatch(setNewsProgress(true));
      }
    }
    setY(scrollY);
  }, [y]);

  // 回傳 url
  const linkPath = () => {
    if (typeof window !== "undefined") return window.location.href;
  };

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    dispatch(setNewsProgress(false));
  }, [pathname]);

  if (newsSlug || academySlug)
    return (
      <div
        className={`${
          academySlug ? "hidden" : ""
        } trans fixed w-screen ulg:block left-0 z-[12] bg-white border-b-1 border-black2
        px-[30px] flex items-center ulg:bottom-0 ulg:top-[unset] ulg:bg-[rgba(255,255,255,.8)] ulg:backdrop-blur-[10px]
        dark:ulg:bg-[rgba(14,14,14,.56)] ulg:border-t-1 ulg:border-b-0 ulg:border-[#e4e4e4] usm:px-[20px]
         ${showNewsProgress ? "top-0" : "top-[-60px]"}`}
      >
        <div
          style={{ width: newsBar?.progress + "%" }}
          className={`h-[2px] absolute z-[1] left-0 bottom-[0px] bg-black ulg:hidden`}
        />

        <div className="h-[54px] max-w-[1440px] mx-auto w-full between-center gap-x-[30px] relative">
          {/* 進度條 */}

          {/* 新聞標題 */}
          <p className="text-bd1b text-black line-clamp-1 ulg:hidden">
            {newsBar?.title}
          </p>

          {/* 手機版目錄按鈕 */}
          <button
            className="hidden ulg:block text-bd3n text-[#fff] bg-[#393939] rounded-[40px] py-[6px] px-[30px] uxs:px-[15px] flex-shrink-0"
            onClick={() => dispatch(setCatalog(!showCatalog))}
          >
            {locale === "tw" ? "目錄" : "目录"}
          </button>

          <div className="flex items-center [&>*]:after:content-none gap-x-[15px] [&>*]:img-box [&>*]:w-[30px] [&>*]:h-[30px] [&>*]:trans uxs:gap-x-[10px]">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${linkPath()}`}
              target="_blank"
              rel="noreferrer noopener"
              className="hover:translate-y-[-2px]"
            >
              <Image
                className="dark:hidden"
                width={35}
                height={35}
                src="/images/icon/icon_facebook_light.svg"
                loading="lazy"
                alt="facebook_share"
              />
              <Image
                className="hidden dark:inline-block"
                width={35}
                height={35}
                src="/images/column/icon_dark_facebook.svg"
                loading="lazy"
                alt="facebook_share"
              />
            </a>

            <a
              href={`https://telegram.me/share/url?url=${linkPath()}`}
              target="_blank"
              rel="noreferrer noopener"
              className="hover:translate-y-[-2px]"
            >
              <Image
                className="dark:hidden"
                width={35}
                height={35}
                src="/images/icon/icon_telegram_light.svg"
                loading="lazy"
                alt="telegram_share"
              />
              <Image
                className="hidden dark:inline-block"
                width={35}
                height={35}
                src="/images/column/icon_dark_telegram.svg"
                loading="lazy"
                alt="telegram_share"
              />
            </a>

            <a
              href={`https://twitter.com/share?url=${linkPath()}`}
              target="_blank"
              rel="noreferrer noopener"
              className="hover:translate-y-[-2px]"
            >
              <Image
                className="dark:hidden"
                width={35}
                height={35}
                src="/images/icon/icon_twitter_light.svg"
                loading="lazy"
                alt="twitter_share"
              />
              <Image
                className="hidden dark:inline-block"
                width={35}
                height={35}
                src="/images/column/icon_dark_twitter.svg"
                loading="lazy"
                alt="twitter_share"
              />
            </a>
            <a
              href={`https://social-plugins.line.me/lineit/share?url=${linkPath()}`}
              target="_blank"
              rel="noreferrer noopener"
              className="hover:translate-y-[-2px]"
            >
              <Image
                className="dark:hidden"
                width={35}
                height={35}
                src="/images/icon/icon_line_light.svg"
                loading="lazy"
                alt="line_share"
              />
              <Image
                className="hidden dark:inline-block"
                width={35}
                height={35}
                src="/images/column/icon_dark_line.svg"
                loading="lazy"
                alt="line_share"
              />
            </a>
            <button
              onClick={() => copy(linkPath())}
              className="hover:translate-y-[-2px]"
            >
              <Image
                className="dark:hidden"
                width={35}
                height={35}
                src="/images/icon/icon_copy_link.svg"
                loading="lazy"
                alt="copy_link"
              />
              <Image
                className="hidden dark:inline-block"
                width={35}
                height={35}
                src="/images/column/icon_dark_copy.svg"
                loading="lazy"
                alt="copy_link"
              />
            </button>
          </div>
        </div>
      </div>
    );

  return <></>;
};

export default NewsProgressBar;
