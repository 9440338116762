import { createSlice } from "@reduxjs/toolkit";
import moment from "../common/ccMoment";

const initialState = {
  notification: [], // 通知
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    clearNotification: (state) => {
      state.notification = null;
    },
    setNewNotification: (state, action) => {
      state.notification.unshift(action.payload);
    },
    // 標示單則通知已讀
    markOneNotificationAsRead: (state, action) => {
      state.notification = state.notification.map((element) => {
        if (element.id === action.payload) {
          const newElement = { ...element };
          newElement.readTime = moment().toISOString();

          return newElement;
        }

        return element;
      });
    },
    // 已讀全部通知
    markAllNotificationAsRead: (state) => {
      const curTime = moment().toISOString();
      state.notification = state.notification.map((element) => {
        const newElement = { ...element };
        newElement.readTime = curTime;

        return newElement;
      });
    },
  },
});

export const notificationState = (state) => state.notification;

export const {
  setNotification,
  setNewNotification,
  markOneNotificationAsRead,
  clearNotification,
  markAllNotificationAsRead,
} = notificationSlice.actions;

export default notificationSlice.reducer;
