"use client";

import { FOOTER_SOCIAL_LINK } from "@/common/constant";
import Image from "next/image";

const FooterSocial = () => {
  return (
    <div className="center-center space-x-[8px] usm:order-[-1]">
      {FOOTER_SOCIAL_LINK.map((l, i) => (
        <a
          key={l.source + i}
          target="_blank"
          rel="noreferrer noopener"
          href={l.link}
        >
          <Image
            className="hover:filter-pale dark:filter-white w-full aspect-[1/1]"
            width={30}
            height={30}
            src={`/images/layout/footer_${l.source}.svg`}
            alt={l.source}
            loading="lazy"
            size="28px"
          />
        </a>
      ))}
    </div>
  );
};

export default FooterSocial;
