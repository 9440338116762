"use client";
// 加密學院彈窗

import Link from "next/link";
import Image from "next/image";
import { ACADEMY_CATEGORY_MAP, CDN_BASE } from "@/common/constant";

const AcademyModal = ({ t, dispatch, showAcademyModal, setAcademyModal }) => {
  function capitalize(str) {
    return str.slice(0, 1) + str.toLowerCase().slice(1, str.length);
  }

  return (
    <div
      className={`trans fixed left-0 top-0 w-screen h-screen ulg:hidden pt-[54px] ${
        showAcademyModal ? "z-[100]" : "z-[-2]"
      }`}
    >
      <div
        className="absolute top-[0] left-0 w-full h-[55px] z-[-1]"
        onClick={() => dispatch(setAcademyModal(false))}
      />
      <div
        className="absolute top-[55px] left-0 w-full h-full z-[-1]"
        onClick={() => dispatch(setAcademyModal(false))}
        onMouseEnter={() => dispatch(setAcademyModal(false))}
      />
      <div
        className={`relative trans z-[1] w-full bg-white mx-auto border-b-1 border-black2 px-[30px]
        py-[24px] ${showAcademyModal ? "top-[0]" : "top-[-370px]"}`}
      >
        <div className="w-full max-w-[1144px] mx-auto flex items-start">
          <div className="text-bd4b text-black space-y-[16px] text-left underline decoration-transparent [&>*]:block flex-shrink-0 pt-[15px]">
            {/* 加密線上學院 */}
            <Link className="trans hover:decoration-black" href="/academy">
              {t("CAMPUS")}
            </Link>

            {/* 加密詞條庫 */}
            <Link
              className="trans hover:decoration-black"
              href="/knowledge-base"
            >
              {t("BASE")}
            </Link>
          </div>

          <div
            className="between-center pl-[50px] ml-[50px] border-l-1 border-dashed border-black3  flex-grow flex-shrink-0 ulb:pl-[25px] ulb:ml-[25px]
          [&>*]:w-[22.5%]"
          >
            {Object.values(ACADEMY_CATEGORY_MAP)?.map((v, i) => {
              if (i === 0)
                return (
                  <Link
                    href={`/academy#${v.toLowerCase()}`}
                    className="img-box block rounded-[2px] overflow-hidden w-full aspect-[211/287] border-1 border-black1  group
                  pt-[14px] px-[23px] [&>p]:relative [&>p]:z-[2]"
                    key={i + v + "academy"}
                    onClick={() => dispatch(setAcademyModal(false))}
                  >
                    <p className="text-bd1b text-jblack mb-[2px]">{t(v)}</p>
                    <p className="text-bd3n text-black8">
                      {capitalize(v)} Academy
                    </p>
                    <div
                      className="absolute top-0 left-0 w-full h-full bg-white z-[1] trans
                  group-hover:bg-black1"
                    />
                    <Image
                      className="z-[2]"
                      width={211}
                      height={287}
                      loading="lazy"
                      src={CDN_BASE + `/academy/${v.toLowerCase()}.png`}
                      alt={v}
                    />
                  </Link>
                );
              return (
                // 尚無文章 暫時設為空值
                <div
                  className="img-box block rounded-[2px] overflow-hidden w-full aspect-[211/287] border-1 border-black1  group
                  pt-[14px] px-[23px] [&>p]:relative [&>p]:z-[2] group"
                  key={i + v + "academy"}
                >
                  <div
                    className="absolute top-0 left-0 w-full h-full bg-white z-[1] trans
                    group-hover:bg-black1"
                  />
                  <Image
                    className="z-[2]"
                    width={211}
                    height={287}
                    loading="lazy"
                    src={`/images/academy/empty_${v.toLowerCase()}.png`}
                    alt={v}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademyModal;
