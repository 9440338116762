// 通知彈窗
import { useSelector } from "react-redux";
import {
  notificationState,
  markOneNotificationAsRead,
  markAllNotificationAsRead,
} from "@/slices/notificationSlice";
import moment from "moment";
import notificationClient from "@/common/apiClient/notificationClient";
import { sendReadStatus } from "@/common/socketFunctions";
import { userState } from "@/slices/userSlice";
import { sify } from "chinese-conv"; // 轉簡體用

// 一則通知
const Notify = ({ notify, uid, dispatch, locale }) => {
  // 點擊通知
  const onClickNotify = async (notify) => {
    if (notify?.url && notify?.url !== null) window.open(notify?.url);

    // 如果已經已讀不用更新
    if (notify?.readTime && !notify?.url) return;

    dispatch(markOneNotificationAsRead(notify.id));

    try {
      const personalIds = [];
      const publicIds = [];

      if (notify.class === "personal") {
        personalIds.push(notify.id);
      } else {
        publicIds.push(notify.id);
      }

      const payload = {
        uid,
        personalIds,
        publicIds,
      };

      // update readTime in db
      await notificationClient.putNotifications(payload);

      // 通知其他登入同帳號改變讀取狀態
      await sendReadStatus(payload);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div
      className="relative space-y-[8px] cp"
      onClick={() => onClickNotify(notify)}
    >
      <p className="text-bd1b text-black">
        {locale === "tw" ? notify?.title : sify(notify?.title)}
      </p>
      <p className="text-bd4n text-black8">
        {locale === "tw" ? notify?.content : sify(notify?.content)}
      </p>
      <span className="block text-black3 text-btn2">
        {sify(moment(notify?.sendTime)?.from())}
      </span>
      {/* {notify?.readTime === null && (
        <span className="inline-block absolute z-[1] w-[9px] h-[9px] rounded-[50%] bg-pale right-[22px] top-[50%] translate-y-[-50%]" />
      )} */}
    </div>
  );
};

const NotifyModal = ({
  dispatch,
  showNotifyModal,
  t,
  setNotifyModal,
  locale,
}) => {
  const { notification } = useSelector(notificationState);
  const { user } = useSelector(userState);

  // 已讀全部通知
  const readAll = async () => {
    // 如果已經都已讀 不用更新
    const check = notification.findIndex((element) => !element.readTime);
    if (check < 0) return;

    dispatch(markAllNotificationAsRead());

    try {
      const personalIds = [];
      const publicIds = [];

      for (let i = 0; i < notification.length; i++) {
        if (notification[i].readTime) {
          // no need to update those that have already been read
          continue;
        }

        if (notification[i].class === "personal") {
          personalIds.push(notification[i].id);
        } else {
          publicIds.push(notification[i].id);
        }
      }

      const payload = {
        uid: user?.uid,
        personalIds,
        publicIds,
      };

      // update readTime in db
      await notificationClient.putNotifications(payload);

      // 通知其他登入同帳號改變讀取狀態
      await sendReadStatus(payload);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      className={`fixed w-screen h-screen top-0 left-0 trans flex justify-end moving-element
            ${showNotifyModal ? "z-[9] ulg:z-[100]" : "z-[-1] opacity-0"}`}
    >
      {/* 關閉用 */}
      <div
        className="absolute w-screen h-screen top-0 left-0 z-[0] moving-element"
        onClick={() => dispatch(setNotifyModal(false))}
      />

      {/* 通知內容 */}
      <div
        className={`trans relative z-[1] noScrollY h-full w-[250px] bg-[#ffffff] dark:bg-[#1E1E1E] border-l-1 border-black2
                pb-[27px] px-[22px] pt-[82px] ulg:pt-[27px] backdrop-blur-[10px] bg-opacity-[.85]
                ${
                  !showNotifyModal ? "translate-x-[255px]" : "translate-x-0"
                } moving-element`}
      >
        <div className="flex between-center pb-[20px] border-b-1 border-dashed border-black3">
          <span className="text-black text-bd1b">Notifications</span>
          <button
            onClick={readAll}
            className="flex-shrink-0 trans rounded-[40px] border-1 border-black3 px-[10px] py-[7px] text-btn2 text-black9
            hover:bg-black hover:border-black hover:text-white"
          >
            {t("READ")}
          </button>
        </div>
        <div className="space-y-[20px] pt-[20px]">
          {notification?.length > 0 ? (
            notification?.map((notify, i) => (
              <Notify
                key={notify?.id + i + "notify"}
                notify={notify}
                dispatch={dispatch}
                locale={locale}
              />
            ))
          ) : (
            <p className="text-bd1m text-black text-center mt-[30px]">
              {t("NO_NOTIFY")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotifyModal;
